export const LINE_CHART_COLORS = [
    '#EB3B5A',
    '#FA8231',
    '#F7B731',
    '#20BF6B',
    '#2D98DA',
    '#4B6584',
    '#0FB9B1',
    '#E4592D',
    '#3867D6',
    '#F8608D',
    '#FC795C',
    '#5DEC7D',
    '#A35CFE',
    '#DF46AB',
    '#63CDFB',
    '#5B1592',
    '#57C026',
    '#AF068A',
    '#1D6A99',
    '#B8222B',
    '#C29A33',
    '#147542',
    '#193A87',
    '#CE184E',
]
