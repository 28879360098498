<template lang="pug">
.filter-wrapper
    Tooltip(trigger="clickToToggle" flat @hide="$emit('hide')")
        template(slot="reference")
            slot(name="activator")
                button.filter-button
                    | {{ name }}
                    ChevronDownIcon.filter-icon(
                        :size="16"
                        :class="{'active': isOpened}"
                    )

        .tooltip-content
            .no-items(v-if="!items.length")
                | По выбранным маркетплейсам нет данных
            template(v-else)
                .disclaimer(v-if="disclaimer")
                    | {{ disclaimer }}
                .search-wrapper(v-if="hasSearch")
                    input.search-input(v-model="searchValue" :placeholder="searchPlaceholder")
                    span.search-icon
                    button.clear-search(v-if="searchValue" @click.stop="clearSearch")
                        span.clear-icon
                            CloseIcon

                button.select-all(
                    v-show="hasSelectAll && !searchValue"
                    @click="hasCheckedItem ? $emit('clear-all') : $emit('select-all')"
                )
                    | {{ hasCheckedItem ? 'Clear all' : 'Select all'}}

                .checkboxes-wrapper
                    .no-items(v-if="searchValue && !currentItems.length")
                        | Ничего не найдено. Попробуйте изменить параметры поиска.
                    MultiSelect.select(
                        v-else
                        :items="currentItems"
                        :limit="limit"
                        @select-checkbox="$emit('select-item', $event)"
                    )
</template>

<script>
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'
import MultiSelect from './MultiSelect.vue';
import Tooltip from '@/components/Elements/Tooltip.vue';
import CloseIcon from '@/assets/svg/close.svg';

export default {
    components: {
        ChevronDownIcon,
        MultiSelect,
        Tooltip,
        CloseIcon,
    },

    props: {
        name: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        hasSelectAll: {
            type: Boolean,
            default: false,
        },
        hasSearch: {
            type: Boolean,
            default: true,
        },
        searchPlaceholder: {
            type: String,
            default: '',
        },
        limit: {
            type: Number,
            default: null,
        },
        disclaimer: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            isOpened: false,
            searchValue: '',
        }
    },

    computed: {
        hasCheckedItem() {
            return this.items.some((item) => item.checked);
        },

        currentItems() {
            const searchString = this.searchValue.toLowerCase();
            return this.items.filter((item) => item.name.toLowerCase().includes(searchString));
        }
    },

    methods: {
        clearSearch() {
            this.searchValue = '';
        },
    }
}
</script>

<style scoped lang="scss">
.filter-wrapper {
    position: relative;
}

.filter-button {
    padding-left: 12px;
    padding-right: 8px;
    display: flex; 
    align-items: center;
    min-width: 118px;
    background-color: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #747474;
    outline: none;

    &:focus {
        outline: none;
    }
}

.filter-icon {
    margin-left: auto;
}

.tooltip-content {
    min-width: 333px;
}

.disclaimer {
    position: relative;
    padding: 16px 12px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #000000;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 12px;
        right: 12px;
        height: 1px;
        background-color: #E7E7E7;
    }
}

.search-wrapper {
    position: relative;
    padding: 10px 12px;
    width: 100%;
    height: 48px;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 12px;
        right: 12px;
        height: 1px;
        background-color: #E7E7E7;
    }
}

.search-input {
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    height: 100%;
    font-family: 'OpenSans';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #000000;
    outline: none;

    &::placeholder {
        font-family: 'OpenSans';
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #A3AEBE;
    }
}

.search-icon {
    position: absolute;
    display: block;
    left: 20px;
    top: 16px;
    width: 16px;
    height: 16px;
    background-image: url('~@/assets/img/search.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.clear-search {
    position: absolute;
    padding: 4px;
    right: 12px;
    top: 18px;
    width: 18px;
    height: 18px;
    outline: none;

    &:focus {
        outline: none;
    }
}

.clear-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    color: #9D9DB0;
    
    svg {
        width: 100%;
        height: 100%;
    }
}

.select-all {
    position: relative;
    padding: 16px 12px;
    width: 100%;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #212121;
    outline: none;

    &:focus {
        outline: none;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #9E9E9E;
    }
}

.checkboxes-wrapper {
    position: relative;
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #7A7A7A;
        border-radius: 7px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #9E9E9E;
    }
}

.no-items {
    padding: 16px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #212121;
}
</style>
