<template lang="pug">
ContainerForData(:isLoading="isLoading" width="100%" :class='{"full-chart": fullChart}')
    template(#header-left)
        .card-title {{ title }}
    template(#header-right)
        .flex.items-center
            AppSelect.mr-8(
                v-if="dropDown"
                :items="packageSizeItems"
                :selectedItem="packageSizeValue"
                name="Рамер"
                min-width="82px"
                @select="selectPackageSize($event.id)"
            )

            .additional-data.flex(
                v-if="isAdditionalData"
            )
                .data-label.flex.mr-8 Среднее
                    .data-label-value.ml-8(:class="avgRrpDiffClasses") {{ avgRrpDiffTitle }} %
                .data-label.flex Товаров с отклонением
                    .data-label-value.ml-8 {{ additionalData.rrpDiffProduct }}

            ArrowZoom.zoom-icon(@click="openFullChart")

    template(#data)
        .empty(v-if="!chartDatasets.length") No Data
        .chart-container(v-else)
            LineChart(
                :key="chartKey"
                :options="chartOptions"
                :chartData="chartData"
                :plugins="chartPlugins"
            )
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ContainerForData from '@/components/Nestle/ContainerForData.vue';
import AppSelect from '@/components/Elements/AppSelect.vue';
import LineChart from '@/components/Chart/LineChart.vue';
import ArrowZoom from '@/assets/svg/arrow-zoom.svg'
import { LINE_CHART_COLORS } from '@/constants/priceAnalysis.js';

export default {
    components: {
        ContainerForData,
        AppSelect,
        LineChart,
        ArrowZoom,
    },

    props: {
        isAdditionalData: {
            type: Boolean,
        },
        additionalData: {
            type: Object,
        },
        requestParams: {
            type: Object,
            required: true,
        },
        title: {
          type: String,
          required: true,
        },
        chartId: {
          type: String,
          required: true,
        },
        dropDown: {
          type: Boolean,
          required: false,
          default: false
        }
    },

    data() {
        return {
            data: [],
            isLoading: false,
            fullChart: false,
            chartKey: 1,

            packageSizeItems: [
                {
                    name: 'Кг',
                    id: 'kg',
                },
                {
                    name: 'Упак.',
                    id: 'package',
                }
            ],

            grouppedSelectItems: [],
            isGrouppedSelectDirty: false,

            chartOptions: {
                locale: 'de-DE',
                interaction: {
                    mode: 'index'
                },
                elements: {
                    point: {
                        radius: 5,
                        hoverRadius: 5,
                        pointStyle: 'circle',
                        backgroundColor: '#FFFFFF',
                    }
                },
                plugins: {
                  datalabels: {
                    display: true,
                    color: "#FFFFFF",
                    padding: 4,
                    borderRadius: 4,
                    backgroundColor: (context) => {
                      return context.dataset.borderColor
                    },
                    offset: [30, 100],
                    onHover: function (e) {
                      e.native.target.style.cursor = 'pointer';
                    },
                    onLeave: function (e) {
                      e.native.target.style.cursor = 'default';
                    }
                  },

                  tooltip: {
                    intersect: true,
                    callbacks: {
                      label: (context) => {
                          const label = context.dataset.label || '';
                          const postfix = this.chartId === 'DYNAMICS_OF_DISCOUNTS' || this.chartId === 'DYNAMICS_OF_DELTA_RRP' ? '%' : ' ₽';

                          return ' ' + label + ': ' + context.formattedValue + postfix;
                      },
                      labelColor: (context) => {
                          return {
                              borderColor: '#FFFFFF',
                              backgroundColor: context.dataset.borderColor,
                          }
                      }
                    }
                  }
                },
            },

            chartPlugins: [
                {
                    id: 'tooltipLine',
                    beforeDraw: (chart) => {
                        const activeElements = chart.getActiveElements();

                        if (activeElements && activeElements.length) {
                            const ctx = chart.ctx;
                            ctx.save();
                            const activePoint = activeElements[0];

                            ctx.beginPath();
                            ctx.setLineDash([4, 4]);
                            ctx.moveTo(activePoint.element.x, chart.chartArea.top);
                            ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = '#9E9E9E';
                            ctx.stroke();
                            ctx.restore();
                        }
                    },
                },
            ],
        }
    },

    computed: {
        ...mapState({
          selectedPackageSizeStore: ({ priceAnalysis }) => priceAnalysis.selectedPackageSizeStore
        }),
        avgRrpDiffClasses() {
            let diff = this.additionalData.avgRrpDiff;
            if (diff >= 0) return '';
            diff *= -1;
            if (diff >= 1 && diff <= 5) return 'orange';
            else if (diff > 5) return 'red';
            return '';
        },
        avgRrpDiffTitle() {
            const arrow = this.additionalData.avgRrpDiff > 0 ? `↑` : `↓`
            return `${arrow} ${this.additionalData.avgRrpDiff}`;
        },
        selectedPackageSize() {
          return this.selectedPackageSizeStore[this.chartId];
        },
        selectedGrouppedItems() {
            const checkedItems = this.grouppedSelectItems.filter((item) => item.checked);
            return checkedItems.map((item) => item.id);
        },
        packageSizeValue() {
            const selectValue = this.packageSizeItems.find((item) => item.id === this.selectedPackageSize);
            if (!selectValue) return this.packageSizeItems[0];

            return selectValue;
        },

        chartLabels() {
            const dates = new Set(this.data.map((item) => item.date));
            return Array.from(dates);
        },

        chartDatasets() {
            const datasetLabels = Array.from(new Set(this.data.map((item) => item.name)));

            return datasetLabels.map((label, index) => {
                const itemData = this.data.filter((item) => item.name === label);

                const datasetData = this.chartLabels.map((chartLabel) => {
                    const dataPoint = itemData.find((item) => item.date === chartLabel);
                    return dataPoint?.value ?? null;
                })

                const datasetColor = index >= LINE_CHART_COLORS.length
                ? LINE_CHART_COLORS[index - LINE_CHART_COLORS.length]
                : LINE_CHART_COLORS[index];

                return {
                    label,
                    data: this.handleZeroValues(datasetData),
                    borderColor: datasetColor,
                }
            })
        },

        chartData() {
            return {
                labels: this.chartLabels,
                datasets: this.chartDatasets,
            }
        },
    },

    methods: {
        ...mapMutations('priceAnalysis', [
          'setSelectedPackageSizeStore',
        ]),
        openFullChart() {
          this.fullChart = !this.fullChart;
          this.chartKey += 1
        },

        handleZeroValues(data) {
          return data.map((value) => {
            let newValue = value
            if (newValue === 0) {
              newValue = null;
            } 
            return newValue;
          });
        },

        selectPackageSize(itemId) {
            const currentItem = this.packageSizeItems.find((item) => item.id === itemId);
            if (!currentItem) return;
            this.setSelectedPackageSizeStore({key: this.chartId, size: currentItem.id});
            this.fetchData();
        },

        selectGrouppedItem(itemId) {
            const currentItem = this.grouppedSelectItems.find((item) => item.id === itemId);
            if (!currentItem) return;

            currentItem.checked = !currentItem.checked;
            this.isGrouppedSelectDirty = true;
        },

        unselectAllGrouppedItems() {
            this.grouppedSelectItems.forEach((item) => {
                item.checked = false;
            });

            this.isGrouppedSelectDirty = true;
        },

        fetchDataBySelectedGrouppedItems() {
            if (this.isGrouppedSelectDirty) {
                this.fetchData();
            }
        },

        async fetchData() {
            this.isLoading = true;
            this.data = [];
            this.isGrouppedSelectDirty = false;

            const params = {
                ...this.requestParams,
                chartId: this.chartId,
            }

            if (this.chartId === 'DYNAMICS_OF_DELTA_RRP') {
              delete params.competitorBrands
            }

            if (this.dropDown) {
              params.unit = this.selectedPackageSize;
            }

            if (this.selectedGrouppedItems.length) {
                params[this.grouppedItemsSelectOptions.requestField] = this.selectedGrouppedItems;
            }

            try {
                const response = await this.$api.purina.getChartData(params);
                const isDataValid = response && Array.isArray(response.data);
                this.data = isDataValid ? response.data : [];
            } catch {
                this.grouppedSelectItems = [];
            }

            this.isLoading = false;
        }
    },

    async mounted () {
    await this.fetchData();
  },
}
</script>

<style scoped lang="scss">
.red {
    color: #FF0000;
}

.orange {
    color: #EB771B;
}

.data-label {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.data-label-value {
    text-align: center;
    font-weight: 600;
}

.card-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: color(gray-700);
}

.activator {
    padding-left: 12px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    min-width: 140px;
    background-color: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #747474;
    outline: none;

    &:focus {
        outline: none;
    }
}

.icon {
    margin-left: auto;
}

.select-list {
    min-width: 140px;
    box-shadow: 0px 2px 4px 0px #00000024;
}

.value-button {
    padding: 16px 12px;
    width: 100%;
    background-color: #fff;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #212121;
    outline: none;

    &:hover {
        background-color: #DFDFDF;
    }

    &:focus {
        outline: none;
    }
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.chart-container {
    width: 100%;
    height: 100%;
}

.zoom-icon {
  margin-left: 16px;
  cursor: pointer;
}

.full-chart {
  position: fixed;
  inset: 0;
  z-index: 1;
  border: 10px solid #C4C4C4;

  ::v-deep .element-container__content {
    height: 95%;
  }

  ::v-deep .chart {
    height: 100%;
  }
}
</style>
