import { render, staticRenderFns } from "./PriceAnalysisTableSection.vue?vue&type=template&id=278021db&scoped=true&lang=pug&"
import script from "./PriceAnalysisTableSection.vue?vue&type=script&lang=js&"
export * from "./PriceAnalysisTableSection.vue?vue&type=script&lang=js&"
import style0 from "./PriceAnalysisTableSection.vue?vue&type=style&index=0&id=278021db&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "278021db",
  null
  
)

export default component.exports