<template lang="pug">
.page-wrapper
    Menu
        template(#title)
            .flex.items-center
                | Price Analysis

    .container
        template(v-if="isMounted")
            .filters
                MultiSelectWithSearch(
                    name="Селлеры"
                    :items="sellers"
                    searchPlaceholder="Поиск селлеров"
                    hasSelectAll
                    @select-item="selectSeller"
                    @select-all="selectAllSellers(true)"
                    @clear-all="selectAllSellers(false)"
                    @hide="updateSectionsData"
                )
                MultiSelectWithSearch(
                  name="Конкуренты"
                  :items="competitors"
                  searchPlaceholder="Поиск конкурентов"
                  hasSelectAll
                  @select-item="selectCompetitors"
                  @select-all="selectAllCompetitors(true)"
                  @clear-all="selectAllCompetitors(false)"
                  @hide="updateSectionsData"
                )
                MultiSelectWithSearch(
                  name="Вес упаковки"
                  :items="boxWeight"
                  :hasSearch="false"
                  hasSelectAll
                  @select-item="selectBoxWeight"
                  @select-all="selectAllBoxWeight(true)"
                  @clear-all="selectAllBoxWeight(false)"
                  @hide="updateSectionsData"
                )
                MultiSelectWithSearch(
                    name="Кол-во шт в упак."
                    :items="boxSizes"
                    :hasSearch="false"
                    hasSelectAll
                    @select-item="selectBoxSize"
                    @select-all="selectAllBoxSizes(true)"
                    @clear-all="selectAllBoxSizes(false)"
                    @hide="updateSectionsData"
                )
                MultiSelectWithSearch(
                    name="Породы собак"
                    :items="animalBreed"
                    :hasSearch="false"
                    hasSelectAll
                    @select-item="selectAnimalBreed"
                    @select-all="selectAllAnimalBreed(true)"
                    @clear-all="selectAllAnimalBreed(false)"
                    @hide="updateSectionsData"
                )

                .second-filter
                  | Отображать графики
                    
                AppSelect(
                  :items="groupByPeriod" 
                  :selectedItem="groupByPeriodValue"
                  name="Группировка" 
                  @select="selectGroupByPeriod($event.id)"
                )

                AppSelect(
                  :items="groupByItems" 
                  :selectedItem="groupByValue"
                  name="Группировка" 
                  @select="selectGroupBy($event.id)"
                )

                MultiSelectWithSearch(
                  v-if="selectedGroupBy === 'selected_sku'"
                  name="Выберите SKU"
                  :items="SKUItems"
                  hasSelectAll
                  @select-item="selectSKU"
                  @select-all="selectAllSKUItems(true)"
                  @clear-all="selectAllSKUItems(false)"
                  @hide="updateSectionsData"
                )

            .empty-page(v-if="isLoading") 
                Skeleton

            template(v-else)
                section.section.charts-section
                    ChartsGroup(
                        :request-params="sectionRequestParams"
                        :need-update="needUpdateSections"
                    )

                section.section.table-section
                    PriceAnalysisTableSection(
                        :request-params="sectionRequestParams"
                        :need-update="needUpdateTableSections"
                    )
        
        .empty-page(v-else) Пожалуйста, выберите значения фильтра
</template>

<script>
import Menu from '@/components/Menu/Menu.vue';
import Skeleton from '@/components/Nestle/Skeleton/Common.vue'
import AppSelect from '@/components/Elements/AppSelect.vue';
import MultiSelectWithSearch from '@/components/Elements/MultiSelect/MultiSelectWithSearch.vue';
import ChartsGroup from '@/components/PriceAnalysis/ChartsGroup.vue';
import PriceAnalysisTableSection from '@/components/PriceAnalysis/PriceAnalysisTableSection.vue';
import { mapMutations } from 'vuex'

export default {
    components: {
        Menu,
        Skeleton,
        AppSelect,
        MultiSelectWithSearch,
        ChartsGroup,
        PriceAnalysisTableSection,
    },

    props: {
    needUpdateResult: {
      type: Boolean,
      default: false,
    },
    stores: {
      type: String,
      default: '',
    },
    categories: {
      type: String,
      default: '',
    },
    brands: {
      type: String,
      default: '',
    },
    regions: {
      type: String,
      default: '',
    },
    dateFrom: {
      type: String,
      default: '',
    },
    dateTo: {
      type: String,
      default: '',
    },

  },

    data() {
        return {
            isMounted: false,
            isLoading: false,

            animalBreed: [],
            isAnimalBreedDirty: false,

            sellers: [],
            isSellersSelectDirty: false,

            boxSizes: [],
            isBoxSizesSelectDirty: false,

            needUpdateSections: false,
            needUpdateTableSections: false,

            competitors: [],
            isCompetitorsSelectDirty: false,

            boxWeight: [],
            isBoxWeightSelectDirty: false,

            groupByPeriod: [
                {
                    name: 'По дням',
                    id: 'day',
                },
                {
                    name: 'По неделям',
                    id: 'week',
                },
            ],
            selectedGroupByPeriod: 'day',

            groupByItems: [
                {
                    name: 'По клиентам',
                    id: 'selected_marketplace',
                },
                {
                    name: 'По категориям',
                    id: 'selected_category',
                },
                {
                    name: 'По брендам',
                    id: 'selected_brand',
                },
                {
                    name: 'По SKU',
                    id: 'selected_sku',
                },
            ],
            selectedGroupBy: 'selected_marketplace',

            SKUItems: [],
            isSKUItemstSelectDirty: false,
        }
    },

    computed: {
        selectedSellersIds() {
            const sellectedSellers = this.sellers.filter((seller) => seller.checked)
            return sellectedSellers.map((seller) => seller.id);
        },

        selectedCompetitorsIds() {
            const sellectedCompetitors = this.competitors.filter((competitor) => competitor.checked)
            return sellectedCompetitors.map((competitor) => competitor.id);
        },

        selectedBoxSizes() {
            const selectedItems = this.boxSizes.filter((boxSize) => boxSize.checked);
            return selectedItems.map((item) => item.name);
        },

        selectedBoxWeight() {
            const selectedItems = this.boxWeight.filter((boxWeight) => boxWeight.checked);
            return selectedItems.map((item) => item.id);
        },

        selectedAnimalBreed() {
            const selectedItems = this.animalBreed.filter((breed) => breed.checked);
            return selectedItems.map((item) => item.name);
        },

        selectedSKUItems() {
            const selectedItems = this.SKUItems.filter((item) => item.checked);
            return selectedItems.map((item) => item.id);
        },

        sectionRequestParams() {
            return {
                stores: this.stores.split(','),
                categories: this.categories.split(','),
                brands: this.brands.split(','),
                regions: this.regions.split(','),
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                sellers: this.selectedSellersIds,
                competitorBrands: this.selectedCompetitorsIds,
                amount: this.selectedBoxSizes,
                weight: this.selectedBoxWeight,
                animalBreed: this.selectedAnimalBreed,
                sku: this.selectedGroupBy === 'selected_sku' ? this.selectedSKUItems : [],
                groupBy: this.selectedGroupBy,
                periodGroup: this.selectedGroupByPeriod,
            }
        },

        boxRequestParams() {
          return {
            stores: this.stores,
            categories: this.categories,
            brands: this.brands,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            regions: this.regions,
            competitorBrands: this.selectedCompetitorsIds.join(),
          }
        },

        groupByPeriodValue() {
            const selectValue = this.groupByPeriod.find((item) => item.id === this.selectedGroupByPeriod);
            if (!selectValue) return this.groupByItems[0];

            return selectValue;
        },

        groupByValue() {
            const selectValue = this.groupByItems.find((item) => item.id === this.selectedGroupBy);
            if (!selectValue) return this.groupByItems[0];

            return selectValue;
        },
    },

    methods: {
        ...mapMutations('priceAnalysis', ['setSellers', 'setAmount', 'setWeight', 'setPeriodGroup', 'setCompetitors', 'setAnimalBreed']),
        async fetchAnimalBreed() {
            const params = {
                stores: this.stores,
                categories: this.categories,
                brands: this.brands,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
            }

            try {
                const response = await this.$api.purina.getAnimalBreed(params);
                if (response && Array.isArray(response.items)) {
                    this.animalBreed = response.items.map((breed, index) => {
                        return {
                            ...breed,
                            checked: false,
                            id: `animal-breed-id-${index}-${breed.name}`
                        }
                    })
                } else {
                    this.animalBreed = [];
                }
            } catch {
                this.animalBreed = [];
            }
        },

        async fetchSellers() {
            const params =  {
              stores: this.stores,
              categories: this.categories,
              brands: this.brands,
              dateFrom: this.dateFrom,
              dateTo: this.dateTo,
            }

            try {
                const response = await this.$api.purina.getSellersList(params);
                if (response && Array.isArray(response.sellers)) {
                    this.sellers = response.sellers.map((seller) => {
                        return {
                            ...seller,
                            checked: false
                        }
                    })
                } else {
                    this.sellers = []
                }
            } catch {
                this.sellers = [];
            }
        },

        async fetchBoxSizes() {
            try {
                const response = await this.$api.purina.getBoxSizesList(this.boxRequestParams);
                if (response && Array.isArray(response.list)) {
                    this.boxSizes = response.list.map((boxSize, index) => {
                        return {
                            id: index + 1,
                            name: boxSize,
                            checked: false,
                        }
                    })
                } else {
                    this.boxSizes = []
                }
            } catch {
                this.boxSizes = [];
            }
        },

        async fetchBoxWeight() {
            try {
                const response = await this.$api.purina.getBoxWeightList(this.boxRequestParams);
                if (response && Array.isArray(response.list)) {
                    this.boxWeight = response.list.map((boxWeight) => {
                        return {
                            id: boxWeight.val,
                            name: boxWeight.name,
                            checked: false,
                        }
                    })
                } else {
                    this.boxWeight = []
                }
            } catch {
                this.boxWeight = [];
            }
        },

        async fetchCompetitors() {
            try {
                const response = await this.$api.purina.getCompetitorsBrands();
                if (response && Array.isArray(response.brands)) {
                  this.competitors = response.brands.map(brands => {
                    return {
                      ...brands,
                      checked: false
                    }
                  })
                } else {
                  this.competitors = [];
                }
            } catch {
                this.competitors = [];
            }
        },

        async fetchSKUs() {
          const params = {...this.sectionRequestParams};
          delete params.groupBy;
          delete params.periodGroup;
          delete params.sku;

            try {
                const response = await this.$api.purina.getPriceAnalysisSKU(params);
                if (response && Array.isArray(response.list)) {
                    this.SKUItems = response.list.map((item) => {
                        return {
                            ...item,
                            checked: false,
                        }
                    })
                } else {
                    this.SKUItems = []
                }
            } catch {
                this.SKUItems = [];
            }
        },


        selectSeller(itemId) {
            const currentItem = this.sellers.find((item) => item.id === itemId);
            if (!currentItem) return;

            currentItem.checked = !currentItem.checked;
            this.setSellers(this.selectedSellersIds)
            this.isSellersSelectDirty = true;
        },

        selectAnimalBreed(itemId) {
            const currentItem = this.animalBreed.find((item) => item.id === itemId);
            if (!currentItem) return;

            currentItem.checked = !currentItem.checked;
            this.setAnimalBreed(this.selectedAnimalBreed);
            this.isAnimalBreedDirty = true;
        },

        selectCompetitors(itemId) {
            const currentItem = this.competitors.find((item) => item.id === itemId);
            if (!currentItem) return;

            currentItem.checked = !currentItem.checked;
            this.isCompetitorsSelectDirty = true;

            this.fetchBoxSizes();
            this.fetchBoxWeight();
        },

        selectAllSellers(checkedValue) {
            this.sellers.forEach((item) => item.checked = checkedValue);
            this.setSellers(this.selectedSellersIds)
            this.isSellersSelectDirty = true;
        },

        selectAllAnimalBreed(checkedValue) {
            this.animalBreed.forEach((item) => item.checked = checkedValue);
            this.setAnimalBreed(this.selectedAnimalBreed);
            this.isAnimalBreedDirty = true;
        },

        selectAllCompetitors(checkedValue) {
            this.competitors.forEach((item) => item.checked = checkedValue);
            this.isCompetitorsSelectDirty = true;

            this.fetchBoxSizes();
            this.fetchBoxWeight();
        },

        selectBoxSize(itemId) {
            const currentItem = this.boxSizes.find((item) => item.id === itemId);
            if (!currentItem) return;

            currentItem.checked = !currentItem.checked;
            this.setAmount(this.selectedBoxSizes)
            this.isBoxSizesSelectDirty = true;
        },

        selectBoxWeight(itemId) {
            const currentItem = this.boxWeight.find((item) => item.id === itemId);
            if (!currentItem) return;

            currentItem.checked = !currentItem.checked;
            this.setWeight(this.selectedBoxWeight)
            this.isBoxWeightSelectDirty = true;
        },

        selectAllBoxSizes(checkedValue) {
            this.boxSizes.forEach((item) => item.checked = checkedValue);
            this.setAmount(this.selectedBoxSizes)
            this.isBoxSizesSelectDirty = true;
        },

        selectAllSKUItems(checkedValue) {
            this.SKUItems.forEach((item, i) => {
              if (checkedValue && i > 7 ) return
              item.checked = checkedValue
            });

            this.isSKUItemstSelectDirty = true;
        },

        selectSKU(itemId) {
            const currentItem = this.SKUItems.find((item) => item.id === itemId);
            if (!currentItem) return;

            const countSelectedSKUs = this.SKUItems.filter((item) => item.checked).length;

            if (countSelectedSKUs >= 8 && !currentItem.checked) return;

            currentItem.checked = !currentItem.checked;
            this.isSKUItemstSelectDirty = true;
        },

        selectAllBoxWeight(checkedValue) {
            this.boxWeight.forEach((item) => item.checked = checkedValue);
            this.setWeight(this.selectedBoxWeight)
            this.isBoxWeightSelectDirty = true;
        },

        updateSectionsData() {
            const mainFilter = this.isSellersSelectDirty || this.isBoxSizesSelectDirty || this.isCompetitorsSelectDirty || this.isBoxWeightSelectDirty || this.isAnimalBreedDirty;
            const isSelectDirty = mainFilter || this.isSKUItemstSelectDirty;

            if (isSelectDirty && !this.isCompetitorsSelectDirty && !this.isSKUItemstSelectDirty) {
              this.needUpdateTableSections = !this.needUpdateTableSections
            }

            if (mainFilter) {
              this.fetchSKUs();
              this.isSKUItemstSelectDirty = false;
            }

            if (isSelectDirty) {
                this.needUpdateSections = !this.needUpdateSections;
                this.isSellersSelectDirty = false;
                this.isBoxSizesSelectDirty = false;
                this.isCompetitorsSelectDirty = false;
                this.isBoxWeightSelectDirty = false;
                this.isSKUItemstSelectDirty = false;
            }
        },

        selectGroupByPeriod(itemId) {
            const currentItem = this.groupByPeriod.find((item) => item.id === itemId);
            if (!currentItem) return;
            this.needUpdateSections = !this.needUpdateSections;
            this.needUpdateTableSections = !this.needUpdateTableSections
            this.selectedGroupByPeriod = currentItem.id;
            this.setPeriodGroup(this.selectedGroupByPeriod);
        },

        selectGroupBy(itemId) {
            const currentItem = this.groupByItems.find((item) => item.id === itemId);
            if (!currentItem) return;
            this.needUpdateSections = !this.needUpdateSections;
            this.selectedGroupBy = currentItem.id;
        },
    },

    watch: {
        competitors: {
            handler(value) {
                const competitorBrandIds = value.filter(item => item.checked).map(item => item.id);
                this.setCompetitors(competitorBrandIds);
            },
            deep: true,
        },

        async needUpdateResult() {
            this.isMounted = true;
            this.isLoading = true;
            this.isSellersSelectDirty = false;
            this.isBoxSizesSelectDirty = false;
            this.isSKUItemstSelectDirty = false;

            await Promise.all([
                this.fetchSellers(),
                this.fetchBoxSizes(),
                this.fetchBoxWeight(),
                this.fetchSKUs(),
                this.fetchAnimalBreed(),
            ])

            this.isLoading = false;
        },
    },

    mounted() {
      this.fetchCompetitors()
    },
}
</script>

<style  lang="scss">
.container {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.filters {
    display: flex;
    align-items: center;
    gap: 12px;
}

.empty-page {
  font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.second-filter {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  color: #747474;
  margin-left: 12px;
}
</style>
